export function initializeTrackingScripts() {
    // Google tracking script: Always load with minimal tracking
    const gtagScript = document.createElement('script');
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-J5FFCE3VT1';
    gtagScript.async = true;
    document.head.appendChild(gtagScript);

    const gtagInlineScript = document.createElement('script');
    gtagInlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        
        // Minimal tracking before consent
        gtag('consent', 'default', {
            ad_storage: 'denied',
            analytics_storage: 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied'
        });
        
        // Basic configuration (e.g., page views)
        gtag('config', 'G-J5FFCE3VT1', { 'anonymize_ip': true });
    `;
    document.head.appendChild(gtagInlineScript);

    // Check for consent and expand tracking if accepted
    if (localStorage.getItem('cookie_consent') === 'accepted') {
        console.log('Ciacha posmakowane');

        // Update Google Consent Mode
        const gtagConsentScript = document.createElement('script');
        gtagConsentScript.innerHTML = `
            gtag('consent', 'update', {
                ad_storage: 'granted',
                analytics_storage: 'granted'
            });
            
            // Re-configure with full tracking options
            gtag('config', 'G-J5FFCE3VT1');
        `;
        document.head.appendChild(gtagConsentScript);

        // LinkedIn tracking script: Load only after consent
        const liScript = document.createElement('script');
        liScript.src = '/js/tracking-li.js';
        liScript.async = true;
        document.head.appendChild(liScript);
    } else {
        console.log('Ciasteczka nieposmakowane, ograniczam śledzie');
    }
}
