<template>
    <div v-if="showBanner" class="cookie-consent-banner">
      <p>
        This website uses cookies to ensure you get the best experience.
        <a :href="policyLink" target="_blank">Learn more</a>
      </p>
      <button @click="declineCookies" class="btn-decline">Decline</button>
      <button @click="acceptCookies" class="btn-accept">
        <span role="img" aria-label="cookie">🍪</span> Accept cookies
      </button>

    </div>
  </template>
  
  <script>
  export default {
    name: "CookieConsent",
    data() {
      return {
        showBanner: false,
        policyLink: "/privacy-policy", // Update this to your privacy policy route
      };
    },
    mounted() {
      // Check if the user has already made a choice
      const consent = localStorage.getItem("cookie_consent");
      if (!consent) {
        this.showBanner = true;
      }
    },
    methods: {
      acceptCookies() {
        localStorage.setItem("cookie_consent", "accepted");
        this.showBanner = false;
        // Add logic to enable cookies or analytics here
      },
      declineCookies() {
        localStorage.setItem("cookie_consent", "declined");
        this.showBanner = false;
        // Add logic to disable cookies or analytics here
      },
    },
  };
  </script>
  
  <style scoped>
  .cookie-consent-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #000;
    color: #fff;
    padding: 15px;
    text-align: center;
    z-index: 1000;
  }
  .cookie-consent-banner p {
    margin: 0;
    display: inline-block;
  }
  .cookie-consent-banner a {
    color: #98bafa;
    text-decoration: underline;
  }
  .cookie-consent-banner .btn-accept,
  .cookie-consent-banner .btn-decline {
    margin-left: 10px;
    padding: 5px 10px;

    border: none;
    cursor: pointer;
  }
  .cookie-consent-banner .btn-accept {
    background-color: #034cc9;
    color: #ffffff;

  }
  .cookie-consent-banner .btn-accept:hover {
    background-color: #0260e8;
  }
  .cookie-consent-banner .btn-decline {
    background-color: #091120;
    color: #394355;
  }
  </style>
  